export const langValuesOptions = {
  EN: {
    name: "en",
    title: "EN",
  },
  UA: {
    name: "uk",
    title: "УКР",
  },
  PL: {
    name: "pl",
    title: "PL",
  },
  RO: {
    name: "ro",
    title: "RO",
  },
  ES: {
    name: "es",
    title: "ES",
  },
};
