const qaTechUk = [
  {
    question: "Ile zasad testowania istnieje?",
    questionId: 1,
    answers: ["5", "6", "7", "8", "9", "Nie wiem"],
    rightAnswer: "7",
  },
  {
    question: "Sprawdzenie",
    questionId: 2,
    answers: [
      "Odpowiada na pytanie, czy odpowiednio utworzono i przetestowano program i czy spełnione są wszystkie wymagania",
      "Podczas procesu sprawdzania, nie dowiadujemy się, czy wszystkie stworzone możliwości funkcjonalne działają prawidłowo i logicznie",
      "Struktura sprawdzania zawiera takie komponenty, jak sprawdzenie weryfikowanych wymagań, techniczną dokumentację i poprawność wykonania kodu programu na dowolnym etapie tworzenia i testowania programu",
      "Odpowiedzi 2 i 3 są prawidłowe",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są prawidłowe",
  },
  {
    question: "Walidacja:",
    questionId: 3,
    answers: [
      "Odpowiada na pytanie, czy produkt jest tworzony zgodnie z oczekiwaniami klienta",
      "Podczas procesu walidacji możemy się przekonać, czy produkt w pełni wpisuje się w zakres działań, których od niego oczekujemy oraz czy klient jest świadom istnienia takiej funkcjonalności",
      "Walidacja zawiera ogólną ocenę programu i może opierać się wyłącznie na subiektywnej opinii, co do prawidłowej pracy programu lub jego komponentów",
      "Odpowiedzi 1 i 3 są prawidłowe",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są prawidłowe",
  },
  {
    question:
      "Które z podanych poniżej opcji są MINUSEM metodologii Waterfall?",
    questionId: 4,
    answers: [
      "Duża przejrzystość tworzenia projektu i jego faz",
      "Stabilność wymagań",
      "Ułatwienie tworzenia planu projektu i zebrania dla niego grupy",
      "Wszystkie wymagania powinny być znane przed rozpoczęciem cyklu życia projektu ",
      "Dokładna kolejność",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer:
      "Wszystkie wymagania powinny być znane przed rozpoczęciem cyklu życia projektu",
  },
  {
    question:
      "Metodologia tworzenia programu nakierowana jest na skrócenie pracy wykonywanej w danym momencie. W tej metodyce: nie ma ograniczeń ani dla czasu, ani dla zadań, ani dla sprintów; zadań jest coraz mniej; nie ma bezpośredniej potrzeby ustanawiania terminu wykonania zadania, ale i tak należy go mierzyć na potrzeby analizy. O jakiej metodologii mówimy?",
    questionId: 5,
    answers: [
      "Waterfall",
      "Scrum",
      "Agile",
      "Kanban",
      "Ten opis odpowiada wszystkim metodologiom",
      "Nie ma właściwej odpowiedzi",
    ],
    rightAnswer: "Kanban",
  },
  {
    question: "Co znaczy ХР w testach?",
    questionId: 6,
    answers: [
      "eXPerience",
      "eXtreme Programming",
      "eXperience Points",
      "XP Injection",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "eXtreme Programming",
  },
  {
    question: "Podstawowe idee Agile",
    questionId: 7,
    answers: [
      "Ludzie i współpraca są ważniejsi niż procesy i narzędzia",
      "Działający produkt jest ważniejszy niż dokumentacja",
      "Współpraca z zamawiającym jest ważniejsza od uzgodnionych warunków umowy",
      "Bycie gotowym na zmiany jest ważniejsze od trzymania się początkowego planu ",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są prawidłowe",
  },
  {
    question:
      "O jakiej metodologii mowa w tych 4 etapach: Początek, Specyfikacja, Projektowanie, Wdrożenie",
    questionId: 8,
    answers: [
      "RUP",
      "Kanban",
      "Scrum",
      "Agile",
      "Waterfall",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "RUP",
  },
  {
    question: "Prawidłowa kolejność elementów Scrum",
    questionId: 9,
    answers: [
      "Planowanie sprintu i tworzenie backlogu sprintu – Praca nad sprintem. Spotkania scrum – Testowanie i prezentacja produktu – Retrospekcja. Planowanie kolejnego sprintu",
      "Tworzenie backlogu produktu – Praca nad sprintem. Spotkania scrum – Testowanie i prezentacja produktu – Retrospekcja. Planowanie kolejnego sprintu",
      "Tworzenie backlogu produktu - Planowanie sprintu i tworzenie backlogu sprintu - Testowanie i prezentacja produktu - Retrospekcja. Planowanie kolejnego sprintu",
      "Tworzenie backlogu produktu - Planowanie sprintu i tworzenie backlogu sprintu  - Praca nad sprintem. Spotkania scrum - Retrospekcja. Planowanie kolejnego sprintu",
      "Tworzenie backlogu produktu - Planowanie sprintu i tworzenie backlogu sprintu - Praca nad sprintem. Spotkania scrum  - Testowanie i prezentacja produktu",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są nieprawidłowe",
  },
  {
    question: "Jakie umiejętności są niezbędne dla project menadżera?",
    questionId: 10,
    answers: [
      "Zebranie i systematyzacja wymagań do projektu",
      "Plan wykonania pracy, KPI, mapy myśli",
      "Poszukiwanie wykonawców, freelancerów, zrobienie portretu idealnego pracownika dla HR",
      "Dystrybucja zadań i kontrola deadline’ów",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są prawidłowe",
  },
  {
    question: "Jakie zadania wykonuje Właściciel Produktu?",
    questionId: 11,
    answers: [
      "Dystrybucja zadań i kontrola terminów",
      "Kontrola kosztów",
      "Prowadzenie dokumentacji projektowej (warunki techniczne, umowy, akty, rachunki, kosztorysy, raporty)",
      "I jeszcze raz – BUDŻET i TERMINY – to najważniejsze w jego pracy",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są nieprawidłowe",
  },
  {
    question: "Product Owner sprawdza",
    questionId: 12,
    answers: [
      "Kto używa produktu i jakie funkcje są ważne dla użytkownika, a jakie są drugorzędne",
      "Jak użytkownicy rozwiązują problemy i jakie problemy napotykają?",
      "Jak przyciągnąć więcej użytkowników, jak chronić obecnych i doprowadzić do wniesienia przez nich opłaty?",
      "Dlaczego użytkownicy przestają korzystać z produktu (może to być niewygodny interfejs, błędy techniczne, brak niezbędnych funkcji lub cena)?",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są prawidłowe",
  },
  {
    question: "Czym są testy dynamiczne?",
    questionId: 13,
    answers: [
      "To testy z wykonaniem wyłącznie kodu z różnymi wyjściowymi wartościami i potwierdzeniem wyników",
      "To testy z wykonaniem wyłącznie programu, z różnymi wyjściowymi wartościami i potwierdzeniem wyników",
      "To testy z wykonaniem kodu lub programu z różnymi wyjściowymi wartościami i potwierdzeniem wyników",
      "To testowanie drogą wykonania kodu",
      "To testowanie z wprowadzaniem różnych rzeczy i sprawdzeniem wyników",
      "Nie wiem",
    ],
    rightAnswer:
      "To testy z wykonaniem kodu lub programu z różnymi wyjściowymi wartościami i potwierdzeniem wyników",
  },
  {
    question: "Czym są testy formalne?",
    questionId: 14,
    answers: [
      "Sprawdzenie programu w odniesieniu do planu testowego, procedur testowych i odpowiedniej dokumentacji z uwzględnieniem życzeń klienta",
      "Walidacja programu w odniesieniu do planu testowego, procedur testowych i odpowiedniej dokumentacji z uwzględnieniem życzeń klienta",
      "Sprawdzenie programu zgodnie z procedurami testów i odpowiednią dokumentacją bez uwzględnienia życzeń klienta",
      "Nie wiem",
    ],
    rightAnswer:
      "Sprawdzenie programu w odniesieniu do planu testowego, procedur testowych i odpowiedniej dokumentacji z uwzględnieniem życzeń klienta",
  },
  {
    question:
      "Stopień przyjazności interfejsu wyznaczany jest zwykle przy pomocy szeregu wskaźników jakości:",
    questionId: 15,
    answers: [
      "Możliwość uczenia się, wydajność pracy, błędy, zadowolenie",
      "Uczenie się, zapamiętywanie, błędy, zadowolenie",
      "Wydajność pracy, zapamiętywanie, błędy, zadowolenie",
      "Możliwość uczenia się, wydajność pracy, zapamiętywanie, błędy",
      "Możliwość uczenia się, wydajność pracy, zapamiętywanie, błędy, zadowolenie",
      "Nie wiem",
    ],
    rightAnswer:
      "Możliwość uczenia się, wydajność pracy, zapamiętywanie, błędy, zadowolenie",
  },
  {
    question: "Czym jest defekt Showstopper",
    questionId: 16,
    answers: [
      "Defekt, który zmusza do wycofania ostatniego releasu",
      "Defekt, który pozwala wykonać pilny release bez testowania",
      "Defekt, który zmusza do zatrzymania procesu testowania",
      "Defekt, to znaczy nieobecność defektu, który powinien być",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Defekt, który zmusza do zatrzymania procesu testowania",
  },
  {
    question: "STLC to –",
    questionId: 17,
    answers: [
      "Etap, na którym wyznaczana jest strategia testów",
      "Cykl testowania programu",
      "Finalny etap testowania programu",
      "Skrót nie ma nic wspólnego z testami",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Cykl życiowy testowania programu",
  },
  {
    question: "Które z powyższych jest zbędne? ",
    questionId: 18,
    answers: [
      "Test plan",
      "Bug report",
      "Backlog",
      "Check list",
      "Cheat list",
      "Zestaw test case’ów i narzędzi",
    ],
    rightAnswer: "Backlog",
  },
  {
    question: " Plan testowy odpowiada na następujące pytania",
    questionId: 19,
    answers: [
      "Co trzeba przetestować",
      "Co zostanie przetestowane",
      "Jak i kiedy będzie to testowane",
      "Początek testów i kryteria zakończenia",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są prawidłowe",
  },
  {
    question: "Co z kryteriów uruchomienia testów jest zbędne?",
    questionId: 20,
    answers: [
      "SRS - software",
      "FRS",
      "Use case",
      "Test plan",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Wszystkie odpowiedzi są nieprawidłowe",
  },
  {
    question: "Co z kryteriów przejścia testów jest niepotrzebne?",
    questionId: 21,
    answers: [
      "Testing report",
      "Metrics",
      "Test Analysis Report",
      "Test case",
      "Wszystkie odpowiedzi są prawidłowe",
      "Wszystkie odpowiedzi są nieprawidłowe",
    ],
    rightAnswer: "Test case",
  },
  {
    question:
      "To kompleksowe testowanie przez użytkowników jednoczesnego dostępu do programu w celu sprawdzenia wpływu na kod, moduł lub bazę danych. Podstawową funkcją jest znajdowanie w kodzie ślepych zaułków.",
    questionId: 22,
    answers: [
      "Currency testing",
      "Testing the web application",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Nie wiem",
    ],
    rightAnswer: "Currency testing",
  },
  {
    question:
      "Testy tego typu przeprowadzane są na stronie internetowej w celu sprawdzenia ładowania, wyświetlania, bezpieczeństwa, funkcjonalności, interfejsu, zgodności i innych problemów związanych z wygodą korzystania.",
    questionId: 23,
    answers: [
      "Currency testing",
      "Testowanie aplikacji webowej",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Nie wiem",
    ],
    rightAnswer: "Testowanie aplikacji webowej",
  },
  {
    question:
      "Testowanie elementów (lub testowanie poboczne) pozwala sprawdzić indywidualną pracę jednostek kodu wyjściowego",
    questionId: 24,
    answers: [
      "Currency testing",
      "Testowanie aplikacji webowej",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Nie wiem",
    ],
    rightAnswer: "Functional testing",
  },
  {
    question:
      "Testowanie interfejsu sprawdza współpracę poszczególnych modułów. Najczęściej wykorzystywane jest do testowania interfejsu użytkownika programów z interfejsem graficznym.",
    questionId: 25,
    answers: [
      "Currency testing",
      "Testing the web application",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Nie wiem",
    ],
    rightAnswer: "Interface testing",
  },
  {
    question:
      "Te testy przeprowadza się, gdy program jest już gotowy do wypuszczenia i trzeba sprawdzić, czy odpowiada wymaganiom.",
    questionId: 26,
    answers: [
      "Currency testing",
      "Testing the web application",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Nie wiem",
    ],
    rightAnswer: "Gamma testing",
  },
];
export default qaTechUk;
